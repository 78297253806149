@charset "utf-8";

// Set your brand colors
$decathlon-blue: #0082c3;

// Update Bulma's global variables
$primary: $decathlon-blue;
$link: $decathlon-blue;

$navbar-breakpoint: 1px;

// Import only what you need from Bulma
@import '~/node_modules/bulma/bulma.sass';

// Styling form inputs for visual validation
input,
select {
  &:invalid {
    border-color: $danger;
  }
  /* &:valid {
    border-color: $primary
  } */
}

.navbar-item img {
  max-width: 100%;
  width: unset;
}
.navbar-item img {
  max-height: 60px;
}
@include mobile {
  button.is-small-mobile {
    @include button-small;
  }
  .select.is-small-mobile,
  .input.is-small-mobile {
    @include control-small;
  }
}
@include mobile {
}
